<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date active" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #003</h2>
                        <p class="newsletter-subtitle">
                            "Exercise Selection”
                        </p>
                        <p class="newsletter-date">
                            (Mar 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            It’s March, and most people are starting to fall off the ‘New Year – New You’ resolution. Motivation is at an all time low, and bad habits are creeping back into everyday life. Easter is just 4 weeks away and the supermarkets are filled with sugar, and temptation.
                        </p>
                        <p class="newsletter-paragraph">
                            Those who are still going strong - let’s look at some common examples of muscle weakness and the causes, along with some of my personal favourite exercises which can help strengthen and develop them to improve the way you move.
                        </p>
                        <p class="newsletter-paragraph">
                            <span style="text-decoration: underline;"><strong>Upper Cross Syndrome:</strong></span><br>
                            People who spend significant time sitting at a desk job will almost always have some shoulder rounding and ‘hunch-back’ type symptoms. This is usually caused by a tightness of muscles on the front of the chest, and a weakness on the back. Some common injuries as a result of this condition includes shoulder injury or pain (impingement), back pain and/or neck pain.
                        </p>
                        <p class="newsletter-paragraph">
                            <strong>Best Exercises:</strong><br>
                            Exercises which target external rotators, and lower/middle back. Banded pull-aparts, banded scapula retraction, and serratus push-ups.
                        </p>
                        <p class="newsletter-paragraph">
                            <span style="text-decoration: underline;"><strong>Lower Cross Syndrome:</strong></span><br>
                            There’s a variety of factors that cause this condition, ranging from improper footwear through to excessive weight around the midsection. Like our Upper Cross Syndrome, it is caused by a weakness (of our abdominals) and a tightness (in our lower back). Some common injuries which stem from this condition includes ankle pain, knee pain and/or lower back pain.
                        </p>
                        <p class="newsletter-paragraph">
                            <strong>Best Exercises:</strong><br>
                            Exercises which target hip abductors/extensors and abdominals. Crab walks, Donkey-kicks, and a Pallof press or feet elevated crunches are my pick.
                        </p>
                        <p class="newsletter-paragraph">
                            Check out my Instagram page for videos of these exercises in action - <a class="instagram-url" href="https://www.instagram.com/muskle_/" target="_blank">@muskle_</a>
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - good greens
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
